<template>
  <PrintForm
    :print="print"
    :type="'create'"
    @save="createPrint"
  />
</template>

<script>
import beforeLeaveRouteModal from '@/mixins/beforeLeaveRouteModal.js'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'
export default {
  components: {
    PrintForm: () => import('./PrintForm'),
  },
  mixins: [beforeLeaveRouteModal, checkProfileRoutePermission],
  data: () => ({
    isFormValid: true,
    print: { rows: [{}], vendors: [0] },
    askToLeave: true,
  }),
  methods: {
    createPrint() {
      this.askToLeave = false
      this.$api
        .post('product_prints', this.print)
        .then(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'success',
            text: 'Gravação cadastrada com sucesso',
            confirm: () => {
              this.$router.push({ name: 'print' })
            },
          })
        })
        .catch(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'error',
            text: 'Ops! Falha ao tentar cadastrar esta gravação',
            caption: 'Verifique se os campos estão preenchidos corretamente.',
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
        })
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.askToLeave) {
      this.openModal(
        () => {
          next()
        }, // Caso o modal seja confirmado
        () => {
          next(false)
        } // Caso o modal seja negado
      )
    } else {
      next()
    }
  },
}
</script>
